import {persistor} from '@/redux/store';
import {Locale} from '@/types/index';
import {PayloadAction} from '@reduxjs/toolkit';
import {startCase} from 'lodash';
import {toast} from 'react-toastify';
import {delay, put, select} from 'redux-saga/effects';
import {toastMessageSlice} from '@/redux/slices/toastMessageSlice';
import {appSettingSlice} from '@/redux/slices/appSettingSlice';
import {cartSlice} from '../slices/cartSlice';

export function* startResetEnireAppSceanrio() {
    yield put({type: `${appSettingSlice.actions.resetSettings}`});
    yield put({type: `${cartSlice.actions.resetCart}`});
    // persistor.purge();
}

export function* startEnableLoadingScenario(action: PayloadAction) {
    try {
    } catch (e) {
    } finally {
    }
}

export function* startUpdateCartProductScenario(action: PayloadAction<any>) {
    try {
    } catch (e) {
    } finally {
    }
}

export function* startShowToastMessageScenario(action: PayloadAction<any>) {
    try {
        const {toastMessage} = yield select();
        toast(startCase(toastMessage.content), {type: toastMessage.type});
        yield delay(2000);
        yield put({type: `${toastMessageSlice.actions.hideToastMessage}`});
    } catch (e) {
    } finally {
    }
}

export function* startChangeLangScenario(
    action: PayloadAction<Locale['lang']>,
) {
    // nothing now
}
