import { trans } from "@/constants";
import i18n from "@/i18n/i18next";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import yup from "@/validations";
import moment from "moment";
import "moment/dist/locale/ar";
import React, { useEffect } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";

import VideoModal from "@/Components/VideoModal";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type Props = {
    children: React.ReactNode;
};
export default function MainLayout({ children }: Props): React.ReactNode {
    const {
        locale: { lang, isRTL },
        appSetting: { videoModal },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(lang);
        moment.locale(lang);
        trans.setLocale(lang);
        yup.setLocale({
            mixed: {
                required: () => t("validation.required"),
            },
            number: {
                min: ({ min }) => ({
                    key: t("validation.min"),
                    values: { min },
                }),
                max: ({ max }) => ({
                    key: t("validation.max"),
                    values: { max },
                }),
            },
            string: {
                email: () => t("validation.email"),
                min: ({ min }) => ({
                    key: t("validation.min"),
                    values: min,
                }),
                max: ({ max }) => ({
                    key: t("validation.max"),
                    values: max,
                }),
                matches: () => t("validation.matches"),
            },
        });
    }, [lang]);

    return (
        <I18nextProvider i18n={i18n}>
            <div
                // className={`w-full  mx-auto  p-4 bg-gray-100 ${lang === "ar" ? "suissie-medium" : "font-expo-medium"}`}
                className={`w-full  mx-auto  p-4 bg-gray-100 `}
                dir={lang === "ar" ? "rtl" : "ltr"}
            >
                {children}
                {videoModal.visible && <VideoModal />}
                <ToastContainer
                    position={lang === "en" ? "top-right" : "top-left"}
                    bodyClassName={() =>
                        "flex flex-1 flex-row  items-center font-expo-medium"
                    }
                    toastClassName={` opacity-90`}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={isRTL}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                    limit={1}
                />
            </div>
        </I18nextProvider>
    );
}
