import {appSettingSlice} from '@/redux/slices/appSettingSlice';
import {localeSlice} from '@/redux/slices/localeSlice';
import {toastMessageSlice} from '@/redux/slices/toastMessageSlice';
import {takeLatest} from 'redux-saga/effects';
import {
    startChangeLangScenario,
    startResetEnireAppSceanrio,
    startShowToastMessageScenario,
} from '@/redux/sagas/appSaga';

export function* triggerResetEntireApp() {
    // yield takeLatest(`resetEntireApp`, startResetEnireAppSceanrio);
    yield takeLatest(
        appSettingSlice.actions.resetEntireApp,
        startResetEnireAppSceanrio,
    );
}

export function* triggerEnableLoading() {
    // yield takeLatest(
    //   `${appLoadingSlice.actions.enableAppLoading}`,
    //   startEnableLoadingScenario
    // );
}

export function* triggerShowToastMessage() {
    yield takeLatest(
        [
            `${toastMessageSlice.actions.showToastMessage}`,
            `${toastMessageSlice.actions.showSuccessToastMessage}`,
            `${toastMessageSlice.actions.showErrorToastMessage}`,
            `${toastMessageSlice.actions.showWarningToastMessage}`,
        ],
        startShowToastMessageScenario,
    );
}

export function* triggerChangeLang() {
    yield takeLatest(
        `${localeSlice.actions.setLocale}`,
        startChangeLangScenario,
    );
}
