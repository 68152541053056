import {Cart} from '@/types';
import {createSlice, current, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {isArray, isNull, round, subtract, sumBy} from 'lodash';

const initialState: Cart = {
    coupon: null,
    total: 0,
    discount: 0,
    net_total: 0,
    user: null,
    cartElements: null,
    kind: 'course',
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCoupon: (state, action: PayloadAction<any>) => ({
            ...state,
            coupon: action.payload,
        }),
        setCartKind: (state, action: PayloadAction<Cart['kind']>) => ({
            ...state,
            kind: action.payload,
        }),
        setStudent: (state, action: PayloadAction<any>) => ({
            ...state,
            user: action.payload,
        }),
        setCartElements: (state, action: PayloadAction<any>) => ({
            ...state,
            cartElements: action.payload,
        }),
        resetCart: (state, action: PayloadAction<void>) => ({
            ...initialState,
        }),
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(setCoupon, setCartElements),
            (state, action) => {
                const {coupon, cartElements} = current(state);
                if (!isNull(cartElements) && isArray(cartElements)) {
                    const total: number = round(
                        sumBy(cartElements, 'price'),
                        2,
                    );
                    const discount: number = round(
                        !isNull(coupon)
                            ? coupon.is_percentage
                                ? (coupon.value / 100) * total
                                : coupon.value
                            : 0,
                        2,
                    );
                    const net_total: number = subtract(total, discount);
                    if (total > 0 && net_total > 0) {
                        return {
                            ...state,
                            discount,
                            total,
                            net_total,
                        };
                    } else {
                        return {
                            ...initialState,
                        };
                    }
                }
            },
        );
        builder.addMatcher(isAnyOf(setStudent), (state, action) => {
            return {
                ...state,
                cartElements: null,
                coupon: null,
                total: 0,
                net_total: 0,
                discount: 0,
            };
        });
    },
});

export const {setCoupon, setStudent, setCartElements, setCartKind, resetCart} =
    cartSlice.actions;
