import {combineReducers} from '@reduxjs/toolkit';
import {localeSlice} from '@/redux/slices/localeSlice';
import {appSettingSlice} from '@/redux/slices/appSettingSlice';
import {apiSlice} from '@/redux/api';
import {toastMessageSlice} from '@/redux/slices/toastMessageSlice';
import {cartSlice} from '@/redux/slices/cartSlice';

export const rootReducer = combineReducers({
    [localeSlice.name]: localeSlice.reducer,
    [cartSlice.name]: cartSlice.reducer,
    [appSettingSlice.name]: appSettingSlice.reducer,
    [toastMessageSlice.name]: toastMessageSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
});
