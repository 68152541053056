'use client';
import messages from "@/messages.json";
import Lang from "lang.js";
import { filter, first, split, startCase } from "lodash";
import moment from "moment";
// import Pusher from "pusher-js";
// export const baseUrl = `${process.env.NEXT_PUBLIC_BASE_URL}`;

export const baseUrl = import.meta.env.VITE_APP_URL;
export const apiUrl = `${baseUrl}api/`;
export const appVersion = `0.0.1`;
export const imageUrl = `https://loremflickr.com/`;
export const isLocal = process.env.NODE_ENV !== 'production';

export const imgThumb = import.meta.env.VITE_APP_THUMBNAIL;
export const imgLarge = import.meta.env.VITE_APP_LARGE;
export const fileUrl = import.meta.env.VITE_APP_FILE_URL;
export const xApiKey = import.meta.env.VITE_X_API_KEY;
export const suppressText = true;
export const trans = new Lang({
    messages,
    locale: "en",
    fallback: "ar"
});

export const getTrans = (element: string): string => startCase(trans.get(`general.${element}`))
export const getCurrentModule = (currentRouteName: string): string => split(currentRouteName, ".", 2)[1] ?? ``

export const toEn = (s: any) =>
    s.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, (a: any) => a.charCodeAt(0) & 15);
export const whatsappUrl = (phone: string | number, message?: string) => `https://api.whatsapp.com/send?phone=${phone}&${message ? `text=${message}` : ``}`;
// Pusher.logToConsole = true;
// export const appPusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
//     cluster: "mt1",
// });
export const pusherChannel = window.Echo?.channel("my-channel");

export const getImage = (name?: string) => {
    return `/images/${name}`;
};

export const getThumb = (name?: string) => {
    return `/${imgThumb}${name}`;
};

export const getLarge = (name?: string) => {
    return `/${imgLarge}${name}`;
};

export const getFile = (name?: string) => {
    return `/${fileUrl}${name}`;
};

export const getIcon = (name?: string) => {
    return `/images/icons/ui/${name}`;
};

export const getCourseGroupColor = (status: string) => {
    switch (status) {
        case 'canceled':
            return 'red'

        case 'recorded': return 'green';
        default: return 'gray'
    }
}


export const getLocaleDate = (date: string | Date, isRTL: boolean) => isRTL ? moment(date).format("YYYY/MM/DD") : moment(date).format("DD/MM/YYYY");

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export const SessionStatus: object[] = [
    {
        name: 'not_started',
        color: "gray",
    },
    {
        name: "started",
        color: "green"
    }, {
        name: "finished",
        color: "blue"
    },
    {
        name: "canceled",
        color: "red"
    }
];

export const getSessionStatusColor = (status: string) => {
    const currentStatus: any = first(filter(SessionStatus, (s: any) => s.name === status));
    return currentStatus?.color ?? 'gray';
}

export const CourseGroups = [
    {
        name: "online",
        color: "green"
    },
    {
        name: "attendance",
        color: "blue"
    },
    {
        name: "recorded",
        color: "red"
    }
]


export const settingLinks = (lang: string) => [
    {
        name: "stages",
        href: route(`${lang}.backend.stage.index`),
    },
    {
        name: "grades",
        href: route(`${lang}.backend.grade.index`),
    },
    {
        name: "subjects",
        href: route(`${lang}.backend.subject.index`),
    },
    {
        name: "halls",
        href: route(`${lang}.backend.hall.index`),
    },
    {
        name: "days",
        href: route(`${lang}.backend.day.index`),
    },
    {
        name: "categories",
        href: route(`${lang}.backend.category.index`),
    },
    {
        name: "tags",
        href: route(`${lang}.backend.tag.index`),
    },
    {
        name: "coupons",
        href: route(`${lang}.backend.coupon.index`),
    },
    {
        name: "slides",
        href: route(`${lang}.backend.slide.index`),
    },
    // {
    //     name: "comments",
    //     href: route(`${lang}.backend.comment.index`),
    // },
    {
        name: "questionnaires",
        href: route(`${lang}.backend.questionnaire.index`),
    },
    // {
    //     name: "faqs",
    //     href: route${lang}.(`backend.faq.index`),
    // },
    {
        name: "session_sections",
        href: route(`${lang}.backend.section.index`),
    },
    {
        name: "trees",
        href: route(`${lang}.backend.tree.index`),
    },
    {
        name: "settings",
        href: route(`${lang}.backend.setting.index`),
    },
    {
        name: "roles",
        href: route(`${lang}.backend.role.index`),
    },
    {
        name: "titles",
        href: route(`${lang}.backend.title.index`),
    },
    {
        name: "activities",
        href: route(`${lang}.backend.activity.index`),
    }
];

export const searchModules: any = [
    { id: 1, name: "students", type: "user", query: { role: 'student' }, showFront: false },
    { id: 2, name: "teachers", type: "user", query: { role: 'teacher' }, showFront: false },
    { id: 3, name: "subjects", type: "subject", query: undefined, showFront: false },
    {
        id: 4, name: "online_courses", type: "course",
        query: { group: "online", is_ticket: 0 },
        showFront: true
    },
    {
        id: 5,
        name: "attendance_courses",
        type: "course",
        query: { group: "attendance", is_ticket: 0 },
        showFront: true
    },
    {
        id: 6, name: "recorded_courses", type: "course",
        query: { group: "recorded", is_ticket: 0 },
        showFront: true
    },
    {
        id: 4, name: "online_consultancies", type: "course",
        query: { group: "online", is_ticket: 1 },
        showFront: true
    },
];

export const imagesList = [
    'https://images.unsplash.com/photo-1549980384-d61217e50c4b?q=80&w=2322&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1648682150029-8b24a4f09cfe?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1674654561955-c5a4fa89d446?q=80&w=3987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1663000889108-3fadf1559c3d?q=80&w=3845&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1671548821027-6882d677135f?q=80&w=3024&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1535905496755-26ae35d0ae54?q=80&w=4000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
]
