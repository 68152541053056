import {Locale} from '@/types/index.d';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@/redux/store';

const initialState: Locale = {
    isRTL: true,
    dir: 'rtl',
    lang: 'ar',
    label: 'arabic',
    otherLang: 'en',
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<string | any>) => {
            return {
                dir: action.payload === 'ar' ? 'rtl' : 'ltr',
                isRTL: action.payload === 'ar',
                lang: action.payload,
                label: action.payload === 'ar' ? 'arabic' : 'english',
                otherLang: action.payload === 'ar' ? 'en' : 'ar',
            };
        },
    },
});

export const {setLocale} = localeSlice.actions;
export const isRTL = (state: RootState) => state.locale.isRTL;
export const dir = (state: RootState) => state.locale.dir;
